var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('v-row',{staticClass:"mt-5 pt-5"},[_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"icon":"","to":"/","exact":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto","align-self":"center"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-calendar-question")])],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('h3',{staticClass:"white--text font-weight-bold"},[_vm._v(" ANWESENHEITSLISTEN ")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-xl pa-0",style:(("color: " + (_vm.template.colors.block_text) + ";")),attrs:{"color":_vm.template.colors.blocks}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center pt-0 pr-0",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"rounded-tl-xl pa-3",style:(_vm.filter_selection == 'person'
                  ? ("color: " + (_vm.template.colors.inline_primary_text) + ";")
                  : ("color: " + (_vm.template.colors.block_text) + ";")),attrs:{"color":_vm.filter_selection == 'person'
                  ? _vm.template.colors.primary
                  : _vm.template.colors.blocks},on:{"click":function($event){_vm.filter_selection = 'person'}}},[_c('v-icon',{staticClass:"mb-3",attrs:{"x-large":"","color":_vm.filter_selection == 'person'
                    ? _vm.template.colors.inline_primary_text
                    : _vm.template.colors.block_text}},[_vm._v(" mdi-account ")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("NACH PERSON SUCHEN")])],1)],1),_c('v-col',{staticClass:"text-center pt-0 pl-0",attrs:{"cols":"6"}},[(false)?_c('v-card',{staticClass:"rounded-tr-xl py-3 px-1",style:(_vm.filter_selection == 'veranstaltung'
                  ? ("color: " + (_vm.template.colors.inline_primary_text) + ";")
                  : ("color: " + (_vm.template.colors.block_text) + ";")),attrs:{"color":_vm.filter_selection == 'veranstaltung'
                  ? _vm.template.colors.primary
                  : _vm.template.colors.blocks},on:{"click":function($event){_vm.filter_selection = 'veranstaltung'}}},[_c('v-icon',{staticClass:"mb-3",attrs:{"x-large":"","color":_vm.filter_selection == 'veranstaltung'
                    ? _vm.template.colors.inline_primary_text
                    : _vm.template.colors.block_text}},[_vm._v(" mdi-timetable ")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("NACH VERANSTALTUNG SUCHEN")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pb-0 pt-5",attrs:{"cols":"11"}},[_c('h3',[_vm._v(" Gebe Vornamen und Nachnamen einer Person ein und generiere eine Anwesenheitsliste mit allen Veranstaltungen, die diese Person im gegebenen Zeitraum besucht hat. ")])]),_c('v-col',{staticClass:"pb-0 pt-5",attrs:{"cols":"11"}},[_c('v-text-field',{staticClass:"elevation-0 pb-0",attrs:{"label":"Vorname","light":"","filled":"","rounded":""},model:{value:(_vm.filter.vorname),callback:function ($$v) {_vm.$set(_vm.filter, "vorname", $$v)},expression:"filter.vorname"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"11"}},[_c('v-text-field',{staticClass:"elevation-0 pb-0",attrs:{"label":"Nachname","light":"","filled":"","rounded":""},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1),(false)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"11"}},[_c('v-select',{staticClass:"elevation-0 pb-0",attrs:{"label":"Besuchsart","items":['Sportler', 'Zuschauer', 'Alle'],"light":"","filled":"","rounded":""},model:{value:(_vm.filter.besuchsart),callback:function ($$v) {_vm.$set(_vm.filter, "besuchsart", $$v)},expression:"filter.besuchsart"}})],1):_vm._e(),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"10"}},[_c('h3',[_vm._v("Zeitraum:")])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"5"}},[_c('v-dialog',{ref:"vondatum",attrs:{"return-value":_vm.filter.vondatum,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "vondatum", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "vondatum", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.parseDate(_vm.filter.vondatum),"label":"Von Datum","filled":"","rounded":"","hide-details":"auto","prepend-inner-icon":"mdi-calendar","readonly":"","light":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.vondatum),callback:function ($$v) {_vm.vondatum=$$v},expression:"vondatum"}},[_c('v-date-picker',{attrs:{"locale":"de","first-day-of-week":"1","scrollable":"","min":_vm.earliest_date},model:{value:(_vm.filter.vondatum),callback:function ($$v) {_vm.$set(_vm.filter, "vondatum", $$v)},expression:"filter.vondatum"}},[_c('v-spacer'),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.vondatum = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){return _vm.$refs.vondatum.save(_vm.filter.vondatum)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"pt-0 text-center",attrs:{"cols":"1","align-self":"center"}},[_c('h3',[_vm._v("-")])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"5"}},[_c('v-dialog',{ref:"bisdatum",attrs:{"return-value":_vm.filter.datum,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "datum", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "datum", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.parseDate(_vm.filter.datum),"label":"Bis Datum","filled":"","rounded":"","hide-details":"auto","prepend-inner-icon":"mdi-calendar","readonly":"","light":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.bisdatum),callback:function ($$v) {_vm.bisdatum=$$v},expression:"bisdatum"}},[_c('v-date-picker',{attrs:{"locale":"de","first-day-of-week":"1","scrollable":"","max":_vm.timer.datum},model:{value:(_vm.filter.datum),callback:function ($$v) {_vm.$set(_vm.filter, "datum", $$v)},expression:"filter.datum"}},[_c('v-spacer'),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.bisdatum = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){return _vm.$refs.bisdatum.save(_vm.filter.datum)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('v-col',{staticClass:"text-center pb-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-b-xl pa-4",attrs:{"color":_vm.loading ||
                (_vm.filter_selection == 'person' &&
                  (!_vm.filter.vorname || !_vm.filter.name || !_vm.filter.datum))
                  ? _vm.template.colors.secondary
                  : _vm.template.colors.primary,"disabled":_vm.loading ||
                (_vm.filter_selection == 'person' &&
                  (!_vm.filter.vorname || !_vm.filter.name || !_vm.filter.datum))},on:{"click":function($event){return _vm.getListe()}}},[(!_vm.loading)?_c('span',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-database-sync")]),_vm._v(" LISTE GENERIEREN ")],1):_vm._e(),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.template.colors.inline_primary_text}}):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }