<template>
  <div class="mt-3">
    <v-row class="mt-5 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-calendar-question</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          ANWESENHEITSLISTEN
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :color="template.colors.blocks"
          class="rounded-xl pa-0"
          :style="`color: ${template.colors.block_text};`"
        >
          <v-row justify="center">
            <v-col cols="6" class="text-center pt-0 pr-0">
              <v-card
                class="rounded-tl-xl pa-3"
                :color="
                  filter_selection == 'person'
                    ? template.colors.primary
                    : template.colors.blocks
                "
                :style="
                  filter_selection == 'person'
                    ? `color: ${template.colors.inline_primary_text};`
                    : `color: ${template.colors.block_text};`
                "
                @click="filter_selection = 'person'"
              >
                <v-icon
                  x-large
                  :color="
                    filter_selection == 'person'
                      ? template.colors.inline_primary_text
                      : template.colors.block_text
                  "
                  class="mb-3"
                >
                  mdi-account
                </v-icon>
                <br />
                <span class="font-weight-bold">NACH PERSON SUCHEN</span>
              </v-card>
            </v-col>
            <v-col cols="6" class="text-center pt-0 pl-0">
              <v-card
                class="rounded-tr-xl py-3 px-1"
                :color="
                  filter_selection == 'veranstaltung'
                    ? template.colors.primary
                    : template.colors.blocks
                "
                :style="
                  filter_selection == 'veranstaltung'
                    ? `color: ${template.colors.inline_primary_text};`
                    : `color: ${template.colors.block_text};`
                "
                v-if="false"
                @click="filter_selection = 'veranstaltung'"
              >
                <v-icon
                  x-large
                  :color="
                    filter_selection == 'veranstaltung'
                      ? template.colors.inline_primary_text
                      : template.colors.block_text
                  "
                  class="mb-3"
                >
                  mdi-timetable
                </v-icon>
                <br />
                <span class="font-weight-bold">NACH VERANSTALTUNG SUCHEN</span>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="11" class="pb-0 pt-5">
                  <h3>
                    Gebe Vornamen und Nachnamen einer Person ein und generiere
                    eine Anwesenheitsliste mit allen Veranstaltungen, die diese
                    Person im gegebenen Zeitraum besucht hat.
                  </h3>
                </v-col>
                <v-col cols="11" class="pb-0 pt-5">
                  <v-text-field
                    label="Vorname"
                    class="elevation-0 pb-0"
                    light
                    filled
                    rounded
                    v-model="filter.vorname"
                  ></v-text-field>
                </v-col>
                <v-col cols="11" class="pt-0">
                  <v-text-field
                    label="Nachname"
                    class="elevation-0 pb-0"
                    light
                    filled
                    rounded
                    v-model="filter.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="11" class="pt-0" v-if="false">
                  <v-select
                    label="Besuchsart"
                    :items="['Sportler', 'Zuschauer', 'Alle']"
                    class="elevation-0 pb-0"
                    light
                    filled
                    rounded
                    v-model="filter.besuchsart"
                  ></v-select>
                </v-col>
                <v-col cols="10" class="pt-0">
                  <h3>Zeitraum:</h3>
                </v-col>
                <v-col cols="5" class="pt-0">
                  <v-dialog
                    ref="vondatum"
                    v-model="vondatum"
                    :return-value.sync="filter.vondatum"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="parseDate(filter.vondatum)"
                        label="Von Datum"
                        filled
                        rounded
                        hide-details="auto"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        light
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="de"
                      first-day-of-week="1"
                      v-model="filter.vondatum"
                      scrollable
                      :min="earliest_date"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click="vondatum = false"
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click="$refs.vondatum.save(filter.vondatum)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="1" class="pt-0 text-center" align-self="center">
                  <h3>-</h3>
                </v-col>
                <v-col cols="5" class="pt-0">
                  <v-dialog
                    ref="bisdatum"
                    v-model="bisdatum"
                    :return-value.sync="filter.datum"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="parseDate(filter.datum)"
                        label="Bis Datum"
                        filled
                        rounded
                        hide-details="auto"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        light
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="de"
                      first-day-of-week="1"
                      v-model="filter.datum"
                      scrollable
                      :max="timer.datum"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click="bisdatum = false"
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click="$refs.bisdatum.save(filter.datum)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-center pb-0">
              <v-card
                class="rounded-b-xl pa-4"
                :color="
                  loading ||
                  (filter_selection == 'person' &&
                    (!filter.vorname || !filter.name || !filter.datum))
                    ? template.colors.secondary
                    : template.colors.primary
                "
                :disabled="
                  loading ||
                  (filter_selection == 'person' &&
                    (!filter.vorname || !filter.name || !filter.datum))
                "
                @click="getListe()"
              >
                <span class="font-weight-bold" v-if="!loading">
                  <v-icon class="mr-2">mdi-database-sync</v-icon>
                  LISTE GENERIEREN
                </span>
                <v-progress-circular
                  indeterminate
                  :color="template.colors.inline_primary_text"
                  v-if="loading"
                ></v-progress-circular>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'
import jsPDF from 'jspdf/dist/jspdf.es'
import autoTable from 'jspdf-autotable'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      filter: {
        datum: '',
        vondatum: '',
        today: '',
        startzeit: '',
        endzeit: '',
        vorname: '',
        name: '',
        besuchsart: '',
        veranstaltungsuebergreifend: '',
      },
      filter_selection: 'person',
      anwesenheitsliste: [],
      anwesenheitsliste_zuschauer: [],
      anwesenheitsliste_andere: [],
      generating: false,
      loading: false,
      bisdatum: false,
      vondatum: false,
      endedatum: false,
      earliest_date: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  mounted() {
    if (this.user.data.rolle != 'Vorstand') {
      router.push('/')
    }
    this.filter.datum = this.timer.datum
    var today = new Date()
    var early = today
    early.setDate(today.getDate() - 28)
    this.earliest_date =
      early.getFullYear() +
      '-' +
      (early.getMonth() < 9
        ? '0' + parseInt(early.getMonth() + 1)
        : parseInt(early.getMonth() + 1)) +
      '-' +
      (early.getDate() < 10 ? '0' + early.getDate() : early.getDate())
    this.filter.today =
      today.getFullYear() +
      '-' +
      (today.getMonth() < 10
        ? '0' + parseInt(today.getMonth() + 1)
        : parseInt(today.getMonth() + 1)) +
      '-' +
      (today.getDate() < 10 ? '0' + today.getDate() : today.getDate())
  },
  methods: {
    addNull(number) {
      if (number < 10) {
        return '0' + number
      }
      return number
    },
    parseDate(date) {
      if (date) {
        var datum = date.split('-')
        if (date == this.timer.datum) {
          return 'Heute'
        } else {
          return datum[2] + '.' + datum[1] + '.' + datum[0]
        }
      } else {
        return ''
      }
    },
    parseDate1(date) {
      if (date) {
        var datum = date.split('-')
        return datum[2] + '.' + datum[1] + '.' + datum[0]
      } else {
        return ''
      }
    },
    searchSportstaette(place) {
      if (!place) {
        if (this.user.data.sportstaetten) {
          return '| Sportstätte: ' + this.user.data.sportstaetten[0].name
        } else {
          return ''
        }
      }
      this.user.data.sportstaetten.forEach((places) => {
        if (places.name == place || places.link == place) {
          return '| Sportstätte: ' + places.name
        }
      })
      return '| Sportstätte: ' + place
    },
    array_contains(array) {
      var exists = false
      if (array) {
        array.forEach((person) => {
          //console.log(person)
          if (person.vorname) {
            if (
              person.vorname.trim() == this.filter.vorname.trim() &&
              person.nachname.trim() == this.filter.name.trim()
            ) {
              exists = true
            }
          } else {
            if (
              person ==
              this.filter.vorname.trim() + ' ' + this.filter.name.trim()
            ) {
              exists = true
            }
          }
        })
      }
      //console.log(exists)
      return exists
    },
    async getListe() {
      this.loading = true
      var logs = []
      this.anwesenheitsliste = []
      //console.log(
      //  'Get all persons ' + this.filter.datum + ' bis ' + this.filter.vondatum,
      //)
      var previous_zutritt = ''
      var previous_verlassen = ''
      var previous_sportstaette = ''

      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('zutritt.datum', '<=', this.filter.datum)
        .orderBy('zutritt.datum')
        .orderBy('zutritt.uhrzeit')
        .get()
        .then(async (snap) => {
          var i = 0
          snap.forEach(async (log) => {
            var daten = log.data()
            if (daten.zutritt.datum >= this.filter.vondatum) {
              if (
                (daten.person.vorname.trim() == this.filter.vorname.trim() &&
                  daten.person.name.trim() == this.filter.name.trim()) ||
                (daten.person.vorname == this.filter.vorname &&
                  daten.person.name == this.filter.name) ||
                this.array_contains(daten.weitere_personen)
              ) {
                var zutritt = new Zeit(
                  daten.zutritt.datum,
                  daten.zutritt.uhrzeit,
                  '',
                )
                var verlassen = new Zeit(
                  daten.verlassen.datum,
                  daten.verlassen.uhrzeit,
                  '',
                )

                if (!daten.zutritt.timestamp) {
                  daten.zutritt.timestamp = zutritt.getTimestamp()
                }
                if (!daten.verlassen.timestamp) {
                  daten.verlassen.timestamp = verlassen.getTimestamp()
                }
                var zeitraum = []
                if (daten.zutritt.datum == daten.verlassen.datum) {
                  zeitraum.push(daten.zutritt.datum)
                } else {
                  var current_date = zutritt
                  zeitraum.push(daten.zutritt.datum)
                  while (current_date.getDatum() < daten.verlassen.datum) {
                    var newdatum = new Date(current_date.getTimestamp())
                    newdatum.setDate(newdatum.getDate() + 1)
                    current_date = new Zeit('', '', newdatum.getTime())
                    zeitraum.push(current_date.getDatum())
                    //console.log(current_date.getTimestamp())
                  }
                }
                //console.log(zeitraum)
                var skip = false
                if (daten.sportstaette) {
                  if (
                    previous_zutritt &&
                    previous_verlassen &&
                    previous_sportstaette
                  ) {
                    if (
                      previous_zutritt.getTimestamp() ==
                        zutritt.getTimestamp() ||
                      (previous_verlassen.getTimestamp() >
                        zutritt.getTimestamp() &&
                        previous_sportstaette.name == daten.sportstaette.name)
                    ) {
                      if (
                        previous_verlassen.getTimestamp() >=
                        verlassen.getTimestamp()
                      ) {
                        skip = true
                      } else {
                        zutritt = previous_verlassen
                      }
                    }
                  }
                  previous_zutritt = zutritt
                  previous_verlassen = verlassen
                  previous_sportstaette = daten.sportstaette

                  if (!skip) {
                    await firebase
                      .firestore()
                      .collection('User')
                      .doc(this.user.data.uid)
                      .collection('Anwesenheit')
                      .where('zutritt.datum', 'in', zeitraum)
                      .where('sportstaette.name', '==', daten.sportstaette.name)
                      .orderBy('veranstaltung.id')
                      .orderBy('aktivitaet')
                      .get()
                      .then(async (snap1) => {
                        var personen = []
                        var j = 0
                        await snap1.forEach(async (person) => {
                          const p = person.data()
                          var zutritt, verlassen, time
                          if (!p.zutritt.timestamp) {
                            time = new Zeit(p.zutritt.datum, p.zutritt.uhrzeit)
                            zutritt = time.getTimestamp()
                          } else {
                            zutritt = p.zutritt.timestamp
                          }
                          if (!p.verlassen.timestamp) {
                            time = new Zeit(
                              p.verlassen.datum,
                              p.verlassen.uhrzeit,
                            )
                            verlassen = time.getTimestamp()
                          } else {
                            verlassen = p.verlassen.timestamp
                          }
                          //console.log(zutritt, verlassen)
                          if (
                            daten.verlassen.timestamp >= zutritt &&
                            daten.zutritt.timestamp <= verlassen
                          ) {
                            personen.push(p)
                            if (p.weitere_personen) {
                              p.weitere_personen.forEach((w) => {
                                var w_person = person.data()
                                if (w.vorname) {
                                  w_person.person.vorname = w.vorname
                                  w_person.person.name = w.nachname
                                } else {
                                  var name = w.split(' ')
                                  w_person.person.vorname = name[0]
                                  w_person.person.name = name[1]
                                }
                                personen.push(w_person)
                              })
                            }
                          }
                          j++
                        })
                        var func = () => {
                          //console.log(j, snap1.size)
                          if (j == snap1.size) {
                            i++
                            this.anwesenheitsliste.push({
                              daten: log.data(),
                              personen: personen,
                            })
                          } else {
                            setTimeout(() => {
                              func()
                            }, 100)
                          }
                        }
                        setTimeout(() => {
                          func()
                        }, 100)
                      })
                  } else {
                    i++
                  }
                } else {
                  if (previous_zutritt && previous_verlassen) {
                    if (
                      previous_verlassen.getTimestamp() > zutritt.getTimestamp()
                    ) {
                      if (
                        previous_verlassen.getTimestamp() >
                        verlassen.getTimestamp()
                      ) {
                        skip = true
                      } else {
                        zutritt = previous_verlassen
                      }
                    }
                  }
                  previous_zutritt = zutritt
                  previous_verlassen = verlassen

                  if (!skip) {
                    await firebase
                      .firestore()
                      .collection('User')
                      .doc(this.user.data.uid)
                      .collection('Anwesenheit')
                      .where('zutritt.datum', 'in', zeitraum)
                      .orderBy('veranstaltung.id')
                      .orderBy('aktivitaet')
                      .get()
                      .then(async (snap1) => {
                        var personen = []
                        var j = 0
                        await snap1.forEach(async (person) => {
                          const p = person.data()
                          var zutritt, verlassen, time
                          if (!p.zutritt.timestamp) {
                            time = new Zeit(p.zutritt.datum, p.zutritt.uhrzeit)
                            zutritt = time.getTimestamp()
                          } else {
                            zutritt = p.zutritt.timestamp
                          }
                          if (!p.verlassen.timestamp) {
                            time = new Zeit(
                              p.verlassen.datum,
                              p.verlassen.uhrzeit,
                            )
                            verlassen = time.getTimestamp()
                          } else {
                            verlassen = p.verlassen.timestamp
                          }
                          //console.log(zutritt, verlassen)
                          if (
                            daten.verlassen.timestamp >= zutritt &&
                            daten.zutritt.timestamp <= verlassen
                          ) {
                            personen.push(p)
                            if (p.weitere_personen) {
                              p.weitere_personen.forEach((w) => {
                                var w_person = person.data()
                                if (w.vorname) {
                                  w_person.person.vorname = w.vorname
                                  w_person.person.name = w.nachname
                                } else {
                                  var name = w.split(' ')
                                  w_person.person.vorname = name[0]
                                  w_person.person.name = name[1]
                                }
                                personen.push(w_person)
                              })
                            }
                          }
                          j++
                        })
                        var func = () => {
                          //console.log(j, snap1.size)
                          if (j == snap1.size) {
                            i++
                            this.anwesenheitsliste.push({
                              daten: log.data(),
                              personen: personen,
                            })
                          } else {
                            setTimeout(() => {
                              func()
                            }, 100)
                          }
                        }
                        setTimeout(() => {
                          func()
                        }, 100)
                      })
                  } else {
                    i++
                  }
                }
              } else {
                i++
              }
            } else {
              i++
            }
          })
          var funct = () => {
            //console.log(i, snap.size)
            if (i == snap.size) {
              this.downloadPDF()
              this.loading = false
            } else {
              setTimeout(() => {
                funct()
              }, 100)
            }
          }
          setTimeout(() => {
            funct()
          }, 500)
        })
    },
    async getListeold() {
      if (this.filter_selection == 'person') {
        this.anwesenheitsliste = []
        this.loading = true
        let events = []
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Anwesenheit')
          .where('person.vorname', '==', this.filter.vorname)
          .where('person.name', '==', this.filter.name)
          .where('zutritt.datum', '<=', this.filter.datum)
          .orderBy('zutritt.datum')
          .get()
          .then(async (snap) => {
            await snap.forEach(async (doc) => {
              var anwesenheit = doc.data()
              //console.log(anwesenheit)
              if (anwesenheit.zutritt.datum >= this.filter.vondatum) {
                if (
                  this.filter.besuchsart == 'Alle' ||
                  anwesenheit.aktivitaet == this.filter.besuchsart
                ) {
                  events.push(anwesenheit)
                  if (anwesenheit.weitere_personen.length > 0) {
                    anwesenheit.weitere_personen.forEach((person) => {
                      var name = person.split(' ')
                      var new_anwesenheit = anwesenheit
                      if (name.length == 3) {
                        new_anwesenheit.person.vorname = name[0] + ' ' + name[1]
                        new_anwesenheit.person.name = name[2]
                      } else if (name.length == 2) {
                        new_anwesenheit.person.vorname = name[0]
                        new_anwesenheit.person.name = name[1]
                      } else if (name.length == 1) {
                        new_anwesenheit.person.vorname = name[0]
                      }
                      events.push(new_anwesenheit)
                    })
                  }
                } else if (
                  (this.filter.besuchsart == 'Sportler' &&
                    anwesenheit.aktivitaet == 'Sportler') ||
                  anwesenheit.aktivitaet == 'Schiedsrichter'
                ) {
                  events.push(anwesenheit)
                  if (anwesenheit.weitere_personen.length > 0) {
                    anwesenheit.weitere_personen.forEach((person) => {
                      var name = person.split(' ')
                      var new_anwesenheit = anwesenheit
                      if (name.length == 3) {
                        new_anwesenheit.person.vorname = name[0] + ' ' + name[1]
                        new_anwesenheit.person.name = name[2]
                      } else if (name.length == 2) {
                        new_anwesenheit.person.vorname = name[0]
                        new_anwesenheit.person.name = name[1]
                      } else if (name.length == 1) {
                        new_anwesenheit.person.vorname = name[0]
                      }
                      events.push(new_anwesenheit)
                    })
                  }
                }
              }
            })
          })
          .then(async () => {
            await firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Anwesenheit')
              .where(
                'weitere_personen',
                'array-contains',
                this.filter.vorname + ' ' + this.filter.name,
              )
              .where('zutritt.datum', '<=', this.filter.datum)
              .orderBy('zutritt.datum')
              .get()
              .then(async (snap) => {
                await snap.forEach(async (doc) => {
                  var anwesenheit = {
                    aktivitaet: doc.data().aktivitaet,
                    person: doc.data().person,
                    sportstaette: doc.data().sportstaette,
                    veranstaltung: doc.data().veranstaltung,
                    verlassen: doc.data().verlassen,
                    zutritt: doc.data().zutritt,
                    weitere_personen: doc.data().weitere_personen,
                  }
                  if (anwesenheit.zutritt.datum >= this.filter.vondatum) {
                    if (
                      this.filter.besuchsart == 'Alle' ||
                      anwesenheit.aktivitaet == this.filter.besuchsart
                    ) {
                      if (anwesenheit.weitere_personen.length > 0) {
                        anwesenheit.weitere_personen.forEach((person) => {
                          if (
                            person ==
                            this.filter.vorname + ' ' + this.filter.name
                          ) {
                            var name = person.split(' ')
                            var new_anwesenheit = anwesenheit
                            if (name.length == 3) {
                              new_anwesenheit.person.vorname =
                                name[0] + ' ' + name[1]
                              new_anwesenheit.person.name = name[2]
                            } else if (name.length == 2) {
                              new_anwesenheit.person.vorname = name[0]
                              new_anwesenheit.person.name = name[1]
                            } else if (name.length == 1) {
                              new_anwesenheit.person.vorname = name[0]
                            }
                            events.push(new_anwesenheit)
                          }
                        })
                      }
                    } else if (
                      (this.filter.besuchsart == 'Sportler' &&
                        anwesenheit.aktivitaet == 'Sportler') ||
                      anwesenheit.aktivitaet == 'Schiedsrichter'
                    ) {
                      if (anwesenheit.weitere_personen.length > 0) {
                        anwesenheit.weitere_personen.forEach((person) => {
                          if (
                            person ==
                            this.filter.vorname + ' ' + this.filter.name
                          ) {
                            var name = person.split(' ')
                            var new_anwesenheit = doc.data()
                            if (name.length == 3) {
                              new_anwesenheit.person.vorname =
                                name[0] + ' ' + name[1]
                              new_anwesenheit.person.name = name[2]
                            } else if (name.length == 2) {
                              new_anwesenheit.person.vorname = name[0]
                              new_anwesenheit.person.name = name[1]
                            } else if (name.length == 1) {
                              new_anwesenheit.person.vorname = name[0]
                            }
                            events.push(new_anwesenheit)
                          }
                        })
                      }
                    }
                  }
                })
              })
          })
          .then(async () => {
            await events.forEach(async (event) => {
              var daten = {
                veranstaltung: event.veranstaltung,
                aktivitaet: event.aktivitaet,
                sportstaette: event.sportstaette,
                verein: event.verein,
                zutritt: event.zutritt,
                verlassen: event.verlassen,
                personen: [],
              }
              if (event.veranstaltung) {
                await firebase
                  .firestore()
                  .collection('User')
                  .doc(this.user.data.uid)
                  .collection('Anwesenheit')
                  .where('veranstaltung.id', '==', event.veranstaltung.id)
                  .where('zutritt.datum', '==', event.zutritt.datum)
                  .get()
                  .then(async (snap) => {
                    await snap.forEach((doc) => {
                      var person = {
                        aktivitaet: doc.data().aktivitaet,
                        person: doc.data().person,
                        sportstaette: doc.data().sportstaette,
                        veranstaltung: doc.data().veranstaltung,
                        verlassen: doc.data().verlassen,
                        zutritt: doc.data().zutritt,
                        weitere_personen: doc.data().weitere_personen,
                      }
                      if (
                        person.aktivitaet == event.aktivitaet ||
                        ((person.aktivitaet == 'Trainer' ||
                          person.aktivitaet == 'Betreuer') &&
                          (event.aktivitaet == 'Sportler' ||
                            event.aktivitaet == 'Trainer' ||
                            event.aktivitaet == 'Betreuer'))
                      ) {
                        if (
                          person.zutritt.uhrzeit <= event.verlassen.uhrzeit &&
                          person.verlassen.uhrzeit >= event.zutritt.uhrzeit
                        ) {
                          daten.personen.push(person)
                          if (person.weitere_personen.length > 0) {
                            person.weitere_personen.forEach((w_person) => {
                              var name = w_person.split(' ')
                              var new_anwesenheit = doc.data()
                              if (name.length == 3) {
                                new_anwesenheit.person.vorname =
                                  name[0] + ' ' + name[1]
                                new_anwesenheit.person.name = name[2]
                              } else if (name.length == 2) {
                                new_anwesenheit.person.vorname = name[0]
                                new_anwesenheit.person.name = name[1]
                              } else if (name.length == 1) {
                                new_anwesenheit.person.vorname = name[0]
                              }
                              daten.personen.push(new_anwesenheit)
                            })
                          }
                        }
                      }
                    })
                  })
              } else {
                await firebase
                  .firestore()
                  .collection('User')
                  .doc(this.user.data.uid)
                  .collection('Anwesenheit')
                  .where('zutritt.datum', '==', event.zutritt.datum)
                  .get()
                  .then(async (snap) => {
                    await snap.forEach((doc) => {
                      var person = doc.data()
                      if (
                        person.aktivitaet == event.aktivitaet ||
                        ((person.aktivitaet == 'Trainer' ||
                          person.aktivitaet == 'Betreuer') &&
                          (event.aktivitaet == 'Sportler' ||
                            event.aktivitaet == 'Trainer' ||
                            event.aktivitaet == 'Betreuer'))
                      ) {
                        if (
                          !person.veranstaltung &&
                          person.zutritt.uhrzeit <= event.verlassen.uhrzeit &&
                          person.verlassen.uhrzeit >= event.zutritt.uhrzeit
                        ) {
                          daten.personen.push(person)
                          if (person.weitere_personen.length > 0) {
                            person.weitere_personen.forEach((w_person) => {
                              var name = w_person.split(' ')
                              var new_anwesenheit = person
                              if (name.length == 3) {
                                new_anwesenheit.person.vorname =
                                  name[0] + ' ' + name[1]
                                new_anwesenheit.person.name = name[2]
                              } else if (name.length == 2) {
                                new_anwesenheit.person.vorname = name[0]
                                new_anwesenheit.person.name = name[1]
                              } else if (name.length == 1) {
                                new_anwesenheit.person.vorname = name[0]
                              }
                              daten.personen.push(new_anwesenheit)
                            })
                          }
                        }
                      }
                    })
                  })
              }
              this.anwesenheitsliste.push(daten)
            })
          })
          .then(() => {
            setTimeout(() => {
              this.loading = false
              this.downloadPDF()
            }, 5000)
          })
      }
    },
    async getListe_old() {
      if (this.filter_selection == 'person') {
        this.anwesenheitsliste = []
        this.anwesenheitsliste_zuschauer = []
        this.anwesenheitsliste_andere = []
        this.loading = true
        let events = {
          sportler: [],
          zuschauer: [],
          zuschauer_andere: [],
          andere: [],
        }
        firebase
          .firestore()
          .collectionGroup('Anwesenheit')
          .where('vorname', '==', this.filter.vorname)
          .where('name', '==', this.filter.name)
          .get()
          .then(async (snap) => {
            await snap.forEach(async (doc) => {
              if (doc.exists) {
                if (doc.ref.path) {
                  var path = doc.ref.path.split('/')
                  if (
                    path[1] == this.user.data.uid &&
                    path[2] == 'Veranstaltung'
                  ) {
                    if (doc.data().spielerid) {
                      events.sportler.push(path[3])
                    } else {
                      events.zuschauer.push(path[3])
                      events.zuschauer_andere.push(doc.data())
                      events.zuschauer_andere[
                        events.zuschauer_andere.length - 1
                      ].id = path[3]
                    }
                  } else {
                    if (
                      path[1] == this.user.data.uid &&
                      path[2] == 'Anwesenheit'
                    ) {
                      events.andere.push(doc.data())
                    }
                  }
                }
              }
            })
          })
          .then(async () => {
            if (this.filter.besuchsart != 'Zuschauer') {
              await firebase
                .firestore()
                .collection('User')
                .doc(this.user.data.uid)
                .collection('Veranstaltung')
                .where('beginn.datum', '>=', this.filter.datum)
                .where('beginn.datum', '<=', this.filter.today)
                .orderBy('beginn.datum', 'desc')
                .get()
                .then(async (veranstaltungen) => {
                  await veranstaltungen.forEach(async (veranstaltung) => {
                    if (events.sportler.includes(veranstaltung.id)) {
                      this.anwesenheitsliste.push({
                        veranstaltung: veranstaltung.data(),
                        id: veranstaltung.id,
                        sportler: [],
                      })
                      var index = this.anwesenheitsliste.length - 1
                      await firebase
                        .firestore()
                        .collection('User')
                        .doc(this.user.data.uid)
                        .collection('Veranstaltung')
                        .doc(veranstaltung.id)
                        .collection('Anwesenheit')
                        .orderBy('name', 'asc')
                        .get()
                        .then(async (anwesenheit) => {
                          await anwesenheit.forEach(async (person) => {
                            if (person.data().spielerid) {
                              this.anwesenheitsliste[index].sportler.push(
                                person.data(),
                              )
                            }
                          })
                        })
                    }
                  })
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          })
          .then(async () => {
            if (this.filter.besuchsart != 'Sportler') {
              if (this.filter.veranstaltungsuebergreifend) {
                await firebase
                  .firestore()
                  .collection('User')
                  .doc(this.user.data.uid)
                  .collection('Veranstaltung')
                  .where('beginn.datum', '>=', this.filter.datum)
                  .where('beginn.datum', '<=', this.filter.today)
                  .orderBy('beginn.datum', 'desc')
                  .get()
                  .then(async (veranstaltungen) => {
                    for (let i = 0; i < events.zuschauer_andere.length; i++) {
                      await firebase
                        .firestore()
                        .collection('User')
                        .doc(this.user.data.uid)
                        .collection('Veranstaltung')
                        .doc(events.zuschauer_andere[i].id)
                        .get()
                        .then(async (v) => {
                          if (v.exists) {
                            this.anwesenheitsliste_andere.push({
                              veranstaltung: {
                                datum: v.data().beginn.datum,
                                zutritt: events.zuschauer_andere[i].zutritt,
                                verlassen: events.zuschauer_andere[i].verlassen,
                              },
                              zuschauer: [],
                            })
                            var index = this.anwesenheitsliste_andere.length - 1
                            await veranstaltungen.forEach(
                              async (veranstaltung) => {
                                if (
                                  v.data().beginn.datum ==
                                  veranstaltung.data().beginn.datum
                                ) {
                                  await firebase
                                    .firestore()
                                    .collection('User')
                                    .doc(this.user.data.uid)
                                    .collection('Veranstaltung')
                                    .doc(veranstaltung.id)
                                    .collection('Anwesenheit')
                                    .orderBy('name', 'asc')
                                    .get()
                                    .then(async (anwesenheit) => {
                                      await anwesenheit.forEach(
                                        async (zuschauer) => {
                                          if (
                                            zuschauer.data().verlassen >=
                                              events.zuschauer_andere[i]
                                                .zutritt &&
                                            zuschauer.data().zutritt <=
                                              events.zuschauer_andere[i]
                                                .verlassen
                                          ) {
                                            if (!zuschauer.data().spielerid) {
                                              this.anwesenheitsliste_andere[
                                                index
                                              ].zuschauer.push(zuschauer.data())
                                            }
                                          }
                                        },
                                      )
                                    })
                                }
                              },
                            )
                          }
                        })
                    }
                  })
              } else {
                await firebase
                  .firestore()
                  .collection('User')
                  .doc(this.user.data.uid)
                  .collection('Veranstaltung')
                  .where('beginn.datum', '>=', this.filter.datum)
                  .where('beginn.datum', '<=', this.filter.today)
                  .orderBy('beginn.datum', 'desc')
                  .get()
                  .then(async (veranstaltungen) => {
                    await veranstaltungen.forEach(async (veranstaltung) => {
                      if (events.zuschauer.includes(veranstaltung.id)) {
                        this.anwesenheitsliste_zuschauer.push({
                          veranstaltung: veranstaltung.data(),
                          id: veranstaltung.id,
                          zuschauer: [],
                        })
                        var index = this.anwesenheitsliste_zuschauer.length - 1
                        await firebase
                          .firestore()
                          .collection('User')
                          .doc(this.user.data.uid)
                          .collection('Veranstaltung')
                          .doc(veranstaltung.id)
                          .collection('Anwesenheit')
                          .where('vorname', '==', this.filter.vorname)
                          .where('name', '==', this.filter.name)
                          .get()
                          .then((snap) => {
                            snap.forEach(async (doc) => {
                              await firebase
                                .firestore()
                                .collection('User')
                                .doc(this.user.data.uid)
                                .collection('Veranstaltung')
                                .doc(veranstaltung.id)
                                .collection('Anwesenheit')
                                .orderBy('name', 'asc')
                                .get()
                                .then(async (anwesenheit) => {
                                  await anwesenheit.forEach(
                                    async (zuschauer) => {
                                      if (
                                        zuschauer.data().verlassen >=
                                          doc.data().zutritt ||
                                        zuschauer.data().zutritt <=
                                          doc.data().verlassen
                                      ) {
                                        if (!zuschauer.data().spielerid) {
                                          this.anwesenheitsliste_zuschauer[
                                            index
                                          ].zuschauer.push(zuschauer.data())
                                        }
                                      }
                                    },
                                  )
                                })
                            })
                          })
                      }
                    })
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              }
            }
          })
          .then(async () => {
            if (this.filter.veranstaltungsuebergreifend) {
              await firebase
                .firestore()
                .collection('User')
                .doc(this.user.data.uid)
                .collection('Veranstaltung')
                .where('beginn.datum', '>=', this.filter.datum)
                .where('beginn.datum', '<=', this.filter.today)
                .orderBy('beginn.datum', 'desc')
                .get()
                .then(async (veranstaltungen) => {
                  for (let i = 0; i < events.andere.length; i++) {
                    this.anwesenheitsliste_andere.push({
                      veranstaltung: {
                        datum: events.andere[i].datum,
                        zutritt: events.andere[i].zutritt,
                        verlassen: events.andere[i].verlassen,
                      },
                      zuschauer: [],
                    })
                    var index = this.anwesenheitsliste_andere.length - 1
                    await veranstaltungen.forEach(async (veranstaltung) => {
                      if (
                        events.andere[i].datum ==
                        veranstaltung.data().beginn.datum
                      ) {
                        await firebase
                          .firestore()
                          .collection('User')
                          .doc(this.user.data.uid)
                          .collection('Veranstaltung')
                          .doc(veranstaltung.id)
                          .collection('Anwesenheit')
                          .orderBy('name', 'asc')
                          .get()
                          .then(async (anwesenheit) => {
                            await anwesenheit.forEach(async (zuschauer) => {
                              if (
                                zuschauer.data().verlassen >=
                                  events.andere[i].zutritt &&
                                zuschauer.data().zutritt <=
                                  events.andere[i].verlassen
                              ) {
                                if (!zuschauer.data().spielerid) {
                                  this.anwesenheitsliste_andere[
                                    index
                                  ].zuschauer.push(zuschauer.data())
                                }
                              }
                            })
                          })
                      }
                    })
                  }
                })
            }
          })
          .then(() => {
            setTimeout(() => {
              this.loading = false
              this.downloadOldPDF()
            }, 5000)
          })
      }
    },
    downloadPDF() {
      const doc = new jsPDF({ orientation: 'l', format: 'a4' })
      var index = 1
      var rows = []
      var headers = []
      this.anwesenheitsliste.forEach((v) => {})
      this.anwesenheitsliste.forEach((v) => {
        var veranstaltung = v.daten.veranstaltung

        doc.text(
          this.user.data.verein.name +
            ' ' +
            this.searchSportstaette(v.daten.sportstaette.name) +
            '\n' +
            'Am ' +
            this.parseDate1(v.daten.zutritt.datum) +
            ' von ' +
            v.daten.zutritt.uhrzeit +
            ' bis ' +
            v.daten.verlassen.uhrzeit +
            ' Uhr',
          10,
          10,
        )
        rows = []
        if (veranstaltung.art == 'Wettkampf') {
          headers = [
            [
              'Disziplin',
              'Name',
              'Adresse',
              'Nation',
              'Telefon',
              'Zutritt',
              'Verlassen',
              'Veranstaltung',
              'Aktivität',
              'Info',
            ],
          ]
          v.personen.forEach((p) => {
            var mannschaft =
              p.veranstaltung.art == 'Training'
                ? p.veranstaltung.mannschaft
                : p.veranstaltung.art == 'Wettkampf' ||
                  p.veranstaltung.art == 'Versammlung' ||
                  p.veranstaltung.art == 'Andere'
                ? p.veranstaltung.name
                : p.veranstaltung.mannschaft +
                  ' vs. ' +
                  p.veranstaltung.gastmannschaft
            rows.push([
              p.disziplin ? p.disziplin.name : '',
              p.person.vorname + ' ' + p.person.name,
              p.person.adresse + ', ' + p.person.plz + ' ' + p.person.ort,
              p.person.nation,
              p.person.telefon,
              p.zutritt.uhrzeit + ' Uhr',
              p.verlassen.uhrzeit + ' Uhr',
              p.veranstaltung.art + ': ' + mannschaft,
              p.aktivitaet,
              p.ggg ? this.translate_ggg(p.ggg) : '',
            ])
          })
        } else {
          headers = [
            [
              'Name',
              'Adresse',
              'Telefon',
              'Zutritt',
              'Verlassen',
              'Veranstaltung',
              'Aktivität',
              'Info',
            ],
          ]
          v.personen.forEach((p) => {
            var mannschaft =
              p.veranstaltung.art == 'Training'
                ? p.veranstaltung.mannschaft
                : p.veranstaltung.art == 'Wettkampf' ||
                  p.veranstaltung.art == 'Versammlung' ||
                  p.veranstaltung.art == 'Andere'
                ? p.veranstaltung.name
                : p.veranstaltung.mannschaft +
                  ' vs. ' +
                  p.veranstaltung.gastmannschaft
            rows.push([
              p.person.vorname + ' ' + p.person.name,
              p.person.adresse + ', ' + p.person.plz + ' ' + p.person.ort,
              p.person.telefon,
              p.zutritt.uhrzeit + ' Uhr',
              (p.verlassen.datum > v.daten.verlassen.datum
                ? this.parseDate1(p.verlassen.datum) + ' '
                : '') +
                p.verlassen.uhrzeit +
                ' Uhr',
              p.veranstaltung.art + ': ' + mannschaft,
              p.aktivitaet,
              p.ggg ? this.translate_ggg(p.ggg) : '',
            ])
          })
        }

        autoTable(doc, {
          head: headers,
          body: rows,
          theme: 'striped',
          headStyles: {
            fontStyle: 'bold',
            fillColor: this.template.colors.primary,
            textColor: this.template.colors.inline_primary_text,
          },
          startY: 30,
        })
        index++
        if (index <= this.anwesenheitsliste.length) {
          doc.addPage('a4', 'l')
        }
      })
      var date = new Date()
      doc.save('Anwesenheitslisten_' + date.getTime() + '.pdf')
    },
    translate_ggg(ggg) {
      var str = ''
      if (ggg.geburtsdatum) {
        str += 'Geb.: ' + ggg.geburtsdatum
      }
      if (ggg.getestet === true) {
        if (ggg.getestet === true) {
          str += ' | '
        }
        str += 'Negativ getestet'
      }
      if (ggg.genesen === true) {
        if (ggg.getestet === true) {
          str += ' & '
        }
        str += 'Genesen'
      }
      if (ggg.geimpft === true) {
        if (ggg.getestet === true || ggg.genesen === true) {
          str += ' & '
        }
        str += 'Geimpft'
      }
      return str
    },

    downloadPDF__old() {
      const doc = new jsPDF({ orientation: 'l', format: 'a4' })
      var index = 1
      var rows = []
      var headers = []
      this.anwesenheitsliste.forEach((v) => {
        var veranstaltung = v.veranstaltung
        var mannschaft =
          v.veranstaltung.art == 'Training'
            ? v.veranstaltung.mannschaft
            : v.veranstaltung.art == 'Wettkampf' ||
              v.veranstaltung.art == 'Versammlung' ||
              v.veranstaltung.art == 'Andere'
            ? v.veranstaltung.name
            : v.veranstaltung.mannschaft +
              ' vs. ' +
              v.veranstaltung.gastmannschaft

        doc.text(
          this.user.data.verein.name +
            ' ' +
            this.searchSportstaette(v.sportstaette.name) +
            '\n' +
            v.aktivitaet +
            ' ' +
            veranstaltung.art +
            ' ' +
            mannschaft +
            ' am ' +
            this.parseDate1(v.zutritt.datum) +
            ' von ' +
            v.zutritt.uhrzeit +
            ' bis ' +
            v.verlassen.uhrzeit +
            ' Uhr',
          10,
          10,
        )
        rows = []
        if (v.veranstaltung.art == 'Wettkampf') {
          headers = [
            [
              'Disziplin',
              'Name',
              'Adresse',
              'Nation',
              'Telefon',
              'E-Mail',
              'Zutritt',
              'Verlassen',
            ],
          ]
          v.personen.forEach((p) => {
            rows.push([
              p.disziplin ? p.disziplin.name : '',
              p.person.vorname + ' ' + p.person.name,
              p.person.adresse + ', ' + p.person.plz + ' ' + p.person.ort,
              p.person.nation,
              p.person.telefon,
              p.person.email,
              p.zutritt.uhrzeit + ' Uhr',
              p.verlassen.uhrzeit + ' Uhr',
            ])
          })
        } else {
          headers = [['Name', 'Adresse', 'Telefon', 'Zutritt', 'Verlassen']]
          v.personen.forEach((p) => {
            rows.push([
              p.person.vorname + ' ' + p.person.name,
              p.person.adresse + ', ' + p.person.plz + ' ' + p.person.ort,
              p.person.telefon,
              p.zutritt.uhrzeit + ' Uhr',
              p.verlassen.uhrzeit + ' Uhr',
            ])
          })
        }

        autoTable(doc, {
          head: headers,
          body: rows,
          theme: 'striped',
          headStyles: {
            fontStyle: 'bold',
            fillColor: this.template.colors.primary,
            textColor: this.template.colors.inline_primary_text,
          },
          startY: 30,
        })
        index++
        if (index <= this.anwesenheitsliste.length) {
          doc.addPage('a4', 'l')
        }
      })
      var date = new Date()
      doc.save('Anwesenheitslisten_' + date.getTime() + '.pdf')
    },
    downloadOldPDF() {
      const doc = new jsPDF({ orientation: 'l', format: 'a4' })
      var index = 1
      var rows = []
      var headers = []
      this.anwesenheitsliste.forEach((v) => {
        var veranstaltung = v.veranstaltung
        doc.text(
          this.user.data.verein.name +
            ' ' +
            this.searchSportstaette(veranstaltung.sportstaette) +
            '\n' +
            'Anwesende Sportler' +
            ' ' +
            veranstaltung.art +
            ' ' +
            veranstaltung.mannschaft +
            ' am ' +
            this.parseDate(veranstaltung.beginn.datum) +
            ' von ' +
            veranstaltung.beginn.uhrzeit +
            ' bis ' +
            veranstaltung.ende.uhrzeit +
            ' Uhr',
          10,
          10,
        )
        rows = []
        headers = [['Name', 'Adresse', 'Telefon']]
        v.sportler.forEach((person) => {
          rows.push([
            person.vorname + ' ' + person.name,
            person.adresse + ', ' + person.plz + ' ' + person.ort,
            person.telefon,
          ])
        })

        autoTable(doc, {
          head: headers,
          body: rows,
          theme: 'striped',
          headStyles: {
            fontStyle: 'bold',
            fillColor: this.template.colors.primary,
            textColor: this.template.colors.inline_primary_text,
          },
          startY: 30,
        })
        index++
        if (index <= this.anwesenheitsliste.length) {
          doc.addPage('a4', 'l')
        } else {
          if (this.anwesenheitsliste_zuschauer.length > 0) {
            doc.addPage('a4', 'l')
          } else if (this.anwesenheitsliste_andere.length > 0) {
            doc.addPage('a4', 'l')
          }
        }
      })
      index = 1
      this.anwesenheitsliste_zuschauer.forEach((v) => {
        if (v.zuschauer.length > 0) {
          var veranstaltung = v.veranstaltung
          doc.text(
            this.user.data.verein.name +
              ' ' +
              this.searchSportstaette(veranstaltung.sportstaette) +
              '\n' +
              'Anwesende Zuschauer' +
              ' ' +
              veranstaltung.art +
              ' ' +
              veranstaltung.mannschaft +
              ' am ' +
              this.parseDate(veranstaltung.beginn.datum) +
              ' von ' +
              veranstaltung.beginn.uhrzeit +
              ' bis ' +
              veranstaltung.ende.uhrzeit +
              ' Uhr',
            10,
            10,
          )
          rows = []
          headers = [['Name', 'Adresse', 'Telefon', 'Anwesenheit']]
          v.zuschauer.forEach((person) => {
            rows.push([
              person.vorname + ' ' + person.name,
              person.adresse + ', ' + person.plz + ' ' + person.ort,
              person.telefon,
              person.zutritt + ' - ' + person.verlassen,
            ])
            if (person.weitere_personen) {
              if (person.weitere_personen.length > 0) {
                person.weitere_personen.forEach((wperson) => {
                  rows.push([
                    wperson,
                    person.adresse + ', ' + person.plz + ' ' + person.ort,
                    person.telefon,
                    person.zutritt + ' - ' + person.verlassen,
                  ])
                })
              }
            }
          })

          autoTable(doc, {
            head: headers,
            body: rows,
            theme: 'striped',
            headStyles: {
              fontStyle: 'bold',
              fillColor: this.template.colors.primary,
              textColor: this.template.colors.inline_primary_text,
            },
            startY: 30,
          })
          index++
          if (index <= this.anwesenheitsliste_zuschauer.length) {
            doc.addPage('a4', 'l')
          } else {
            if (this.anwesenheitsliste_andere.length > 0) {
              doc.addPage('a4', 'l')
            }
          }
        }
      })
      index = 1
      this.anwesenheitsliste_andere.forEach((v) => {
        var veranstaltung = v.veranstaltung
        if (v.zuschauer.length > 0) {
          doc.text(
            this.user.data.verein.name +
              ' ' +
              this.searchSportstaette(veranstaltung.sportstaette) +
              '\n' +
              'Anwesende Zuschauer' +
              ' am ' +
              this.parseDate(veranstaltung.datum) +
              ' zwischen ' +
              veranstaltung.zutritt +
              ' und ' +
              veranstaltung.verlassen +
              ' Uhr',
            10,
            10,
          )
          rows = []
          headers = [['Name', 'Adresse', 'Telefon', 'Anwesenheit']]
          v.zuschauer.forEach((person) => {
            rows.push([
              person.vorname + ' ' + person.name,
              person.adresse + ', ' + person.plz + ' ' + person.ort,
              person.telefon,
              person.zutritt + ' - ' + person.verlassen,
            ])
            if (person.weitere_personen) {
              if (person.weitere_personen.length > 0) {
                person.weitere_personen.forEach((wperson) => {
                  rows.push([
                    wperson,
                    person.adresse + ', ' + person.plz + ' ' + person.ort,
                    person.telefon,
                    person.zutritt + ' - ' + person.verlassen,
                  ])
                })
              }
            }
          })

          autoTable(doc, {
            head: headers,
            body: rows,
            theme: 'striped',
            headStyles: {
              fontStyle: 'bold',
              fillColor: this.template.colors.primary,
              textColor: this.template.colors.inline_primary_text,
            },
            startY: 30,
          })
          index++
          if (index <= this.anwesenheitsliste_andere.length) {
            doc.addPage('a4', 'l')
          }
        }
      })
      var date = new Date()
      doc.save('Anwesenheitslisten_' + date.getTime() + '.pdf')
    },
  },
}
</script>
